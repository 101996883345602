<template>
  <div>
    <b-card>
      <div class="carriers-button">
        <b-button
          variant="warning"
          class="push-right"
          @click="goToCreateCarrier"
          ><feather-icon icon="PlusIcon" />Nuevo courier</b-button
        >
      </div>
      <section class="row">
        <filter-swapper
          v-if="!loading.first"
          :buttons="buttons"
          :controlHeightButtons="controlHeight"
          :trigger="selectedRows.length === 0"
          class="col-12"
        >
          <template #slot1>
            <form-render
              :key="keyFormRender"
              :fields="fields"
              :buttonSend="buttonSend"
              class="mb-1"
              :form.sync="formFilter"
              @send="filterList"
              containerButtonsClass="mt-2 ml-1"
            >
              <template #buttons>
                  <b-button
                    variant="outline-light"
                    v-b-tooltip.hover
                    title="Limpiar filtros"
                    class="mx-1"
                    @click="cleanFilter"
                    ><feather-icon icon="RefreshCwIcon"
                  /></b-button>                             
              </template>
            </form-render>
          </template>
        </filter-swapper>
        <div class="flex-md-grow-1 px-md-0 d-flex justify-content-end float-right">
          <div class="d-inline mr-1" v-if="showTransitBtn">
            <b-dropdown :variant="transitDayBtn" class="mb-1">
              <template #button-content>Días adicionales temporales</template>
              <b-dropdown-item @click="openModalUpload('TRANSIT_DAYS')">
                {{ $t('Agregar') }}
              </b-dropdown-item>
              <b-dropdown-item @click="downloadTransitDays" :disabled="loading.downloadTransitDays">
                {{ $t('Exportar') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>  
          <div class="d-inline">
            <b-dropdown v-b-tooltip.hover :title="$t('Descargar e importa condiciones generales de courier')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" :disabled="loading.download">
              <template #button-content>Condiciones Courier</template>
              <b-dropdown-item @click="openModalUpload('CONDITIONS')">
                <feather-icon icon="UploadIcon"/>&nbsp;{{ $t('Importar archivo') }}
              </b-dropdown-item>
              <b-dropdown-item @click="download">
                <feather-icon icon="DownloadIcon"/>&nbsp;{{ $t('Exportar archivo') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>  
        </div>
      </section> 
      <div v-show="!loading.first">
        <table-render
          id="table_folders"
          :schema="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.carriers"
          showCheckboxes="true"
          :selectedRows.sync="selectedRows"
        >
        </table-render>
        <pagination
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
        />
      </div>
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 8"
          :table-props="{}"
        />
      </div>
      <modal-upload-coberturas />
      <modal-upload-conditions />
      <modal-success-upload/>
      <modal-update-transit-days @clearBtn="clearBtn"/>
    </b-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ModalUploadCoberturas from './modals/ModalUploadCoberturas.vue'
import ModalUploadConditions from './modals/ModalUploadConditions.vue'
import ModalUpdateTransitDays from './modals/ModalUpdateTransitDays.vue'
import CarriersService from './carriers.service'
import ModalSuccessUpload from './modals/ModalSuccessUpload.vue'

export default {
  name: 'config-carriers',
  components: { ModalUploadCoberturas, ModalUploadConditions, ModalSuccessUpload, ModalUpdateTransitDays },
  data() {
    return {
      buttons: [],
      formFilter: {},
      selectedRows: [],
      payloadFilter: {},
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      schema: [],
      rows: [],
      fields: [],
      actions: [],
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      loading: {
        first: true,
        carriers: true,
        download: false,
        downloadTransitDays: true
      },
      keyFormRender: 0,
      showingActions: false,
      baseService : new CarriersService,
      modal: {
        COVERAGE: 'modal-upload-coberturas',
        CONDITIONS: 'modal-upload-conditions',
        TRANSIT_DAYS: 'modal-update-transit-days'
      },
      transitDayBtn: 'outline-warning'
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      countries: 'getCountries',
      carriers: 'getCarriers',
      carrieStatus: 'getCarrierStatus'
    }),
    showTransitBtn() {
      return this.mySession?.feature_access?.includes('view-update-transit-dues')
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    carriers() {
      this.rows = this.carriers.rows.map(({services, status: {name: status}, carrier_manager, ...rest}) => ({
        ...rest,
        status: status || 'Sin asignar',
        carrier_manager: this.getCarrierManagerName(carrier_manager),
        service : services.map(({name}) => name).join('-') || 'Sin asignar'
      })
      )
      this.pagination.total = this.carriers.total
      this.selectedRows = []
      this.loading.first = false
      this.loading.carriers = false
    },
    'pagination.page'() {
      this.getCarriers()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getCarriers()
    },
    countries() {
      this.setCountries('watch')
    },
    carrieStatus() {
      const index = this.fields.findIndex(el => el.name === 'carrier_status')
      if (index !== -1) this.fields[index].options = this.carrieStatus
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Id', sortable: true, key: 'id' },
        { label: 'Nombre', sortable: true, key: 'name'},
        { label: 'Pais', sortable: true, key: 'country_carrier' },
        { label: 'Estado', key: 'status' },
        { label: 'Servicios', key: 'service' },
        { label: 'Courier Manager', key: 'carrier_manager' },
        { label: 'Código', key: 'code', class: ['text-center'], style: { width: '15%' } },
        { label: 'Acciones', key: 'actions', class: ['text-center'] }
      ]

      this.fields = [
        { fieldType: 'FieldInput', name: 'search_string', label: 'Nombre / Código', containerClass: 'col-sm-12 container-info col-md-3 col-lg-4', placeholder : 'Ingrese nombre o código del courier' },
        { fieldType: 'FieldSelect', name: 'country_id', label: 'Pais', containerClass: 'col-sm-12 container-info col-md-3', addFlags: true, flagSize : 0 },
        { fieldType: 'FieldSelect', name: 'carrier_status', label: 'Estado', containerClass: 'col-sm-12 container-info col-md-3' }]
      this.buttons = [{ name: 'delete', text: 'Eliminar', color: 'danger', icon: 'TrashIcon', action: this.confirmDeleteMultipleCarriers }]
      this.actions = [
        {
          action: id => this.goToDetails(id), icon: 'FileTextIcon', color: 'primary', text: 'Detalles de Couriers'
        },
        { action: id => this.confirmDeleteCarrier(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }]

      if (this.$route.name !== 'config-carriers-platforms') {
        this.actions.push(
          { action: () => this.openModalUpload('COVERAGE'), icon: 'FileIcon', color: 'primary', text: 'Subir planilla couriers' }
        )
      }
      this.getAllInitialData()
    },
    getAllInitialData() {
      if (!['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        this.$alert(this.$t('msg-error-permission'))
        return null
      } else {
        this.getCarriers()
      }
      if (this.countries && !!this.countries.length) { //Para cuando se cambia de pantalla dentro del sitio sin actualizar
        this.setCountries()
      }
    },
    getCarriers() {
      this.selectedRows = []
      this.loading.carriers = true
      const queryParams = {
        page: this.pagination.page,
        limit: this.pagination.limit,
        ...this.payloadFilter
      }
      this.$store.dispatch('fetchService', { name: 'getCarriers', queryParams })
      this.$store.dispatch('fetchService', { name: 'getCarrierStatus' })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country_id')
      if (index !== -1) this.fields[index].options = Array.isArray(this.countries) ? this.countries : this.countries.data
    },
    download() {
      this.loading.download = true
      this.baseService.callService('downloadGeneralConditions').then(
        res => {
          this.downloadLink(res.data.uploadedAttachments, 'Condiciones-Generales')
          this.$success(this.$t('msg-exito-descarga'))
        }
      ).catch(
        err => {
          console.error(err)
        }
      ).finally(
        () => {
          this.loading.download = false
        }
      )
      return null
    },
    downloadTransitDays() {
    },
    filterList(form) {
      this.payloadFilter = {}
      Object.keys(form).map(key => {
        this.payloadFilter[key] = form[key].id || form[key]
      })
      if (this.pagination.page !== 1) this.pagination.page = 1; else this.getCarriers()
    },
    goToDetails(id) {
      const currentCarrier = this.rows.find(value => value.id === id)
      this.$router.push({ name: 'details-carriers-platforms', params: { id : currentCarrier.id, name: currentCarrier.name, code: currentCarrier.code, breadcrumb: { name: currentCarrier.name } } })
    },
    getCarrierManagerName(manager) {
      if (!manager) return 'Sin asignar'
      const [first, ...rest] = manager.split('@')[0].split('.')
      return `${first[0].toUpperCase()}${first.slice(1)} ${rest.map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}`
    },
    cleanFilter() {
      this.formFilter = {}
      this.keyFormRender++
      this.filterList(this.formFilter)
    },
    confirmDeleteCarrier(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), () => this.deleteCarrier(_id))
    },
    deleteCarrier(id) {
      this.$store.dispatch('fetchService', { name: 'deleteCarriers', params: { carrier_id: id }, onSuccess: this.successDeleteCarrier })
    },
    successDeleteCarrier() {
      this.getCarriers()
      this.$success(this.$t('msg-exito-eliminar-elemento'))
    },
    confirmDeleteMultipleCarriers() {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), this.deleteMultipleCarriers)
    },
    deleteMultipleCarriers() {
      const services = this.selectedRows.map(id => ({ name: 'deleteCarriers', params: { carrier_id: id } }))
      this.$store.dispatch('fetchMultipleServices', { services, showPackSuccess: true, onSuccess: this.successDeleteMultipleCarriers })
    },
    successDeleteMultipleCarriers() {
      this.getCarriers()
      this.$success(this.$t('msg-exito-eliminar-multiple'))
    },
    goToCreateCarrier() {
      this.$router.push({ name: 'create-carriers' })
    },
    openModalUpload(key) {
      this.$bvModal.show(this.modal[key])
      if (key === 'TRANSIT_DAYS') this.transitDayBtn = 'warning'
    },
    clearBtn() {
      this.transitDayBtn = 'outline-warning'
    }
  }
}
</script>

<style lang='scss'>
.carriers-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}

.right-push{
  right: 0;
  position: absolute;
}

.button_container{
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
</style>
